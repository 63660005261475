import * as React from 'react'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import iassign from 'immutable-assign'
import _ from 'lodash'
import validator from 'validator'

import { PartnerAdmin, PattersonStaff, SimplifeyeStaff } from '../../models/enums'
import { CustomFieldType } from '../../models/enums'
import ConfirmModal from '../shared/confirm-modal/ConfirmModal'
import CustomField from '../shared/custom-fields/CustomField'
import CustomMultiselectField from '../shared/custom-fields/CustomMultiselectField'
import CustomTooltipV2 from '../shared/custom-tooltip-v2/CustomTooltipV2'

import './ManageAdmin.sass'

export type ManageAdminProps = {
    admin?: Models.Account
    permissionList: Array<Models.Permission>
    submitButtonText: string
    onClose: () => void
    onSave: (state: Api.AccountUpdate) => void
    onSendPasswordReset?: () => Promise<void>
    onUnlockAccount?: () => Promise<void>
}

export type ManageAdminDispatch = {}

const isEmail = (value: string) => {
    return validator.isEmail(value)
}

type AdminState = {
    name?: string
    username?: string
    permissions?: Array<Models.Permission>
    type?: string
    yext_user_id?: string
}

export type ManageAdminState = {
    isDirty: boolean
    admin: AdminState
    showPasswordResetModal: boolean
    allPermissionsSelected: boolean
}

type Props = ManageAdminProps & ManageAdminDispatch

class ManageAdmin extends React.Component<Props, ManageAdminState> {
    constructor(props: Props) {
        super(props)
        this.state = {
            isDirty: false,
            admin: {},
            showPasswordResetModal: false,
            allPermissionsSelected: false,
        }
    }

    get isUsernameValid() {
        const username = !this.state.admin.username ? this.props.admin?.username : this.state.admin.username
        return Boolean(username && isEmail(username))
    }

    get canSave() {
        const { admin } = this.props
        const { name, username, permissions, type } = this.state.admin

        if (!admin) {
            return !_.isEmpty(name) && this.isUsernameValid && !_.isEmpty(type)
        }

        if (name === '' || username === '' || !this.isUsernameValid) {
            return false
        }

        return name !== admin.name || username !== admin.username || permissions != null
    }

    get isInvalidUsername() {
        if (this.state.admin && this.state.admin.username && this.state.admin.username !== '') {
            return !Boolean(isEmail(this.state.admin.username))
        }
        return false
    }

    onSave = () => {
        if (this.canSave) {
            this.props.onSave({
                ...this.state.admin,
                permissions: this.state.admin.permissions && this.state.admin.permissions.map(p => p.type),
                yext_user_id: this.state.admin.yext_user_id,
            })
            this.props.onClose()
        }
        return
    }

    confirmPasswordReset = () => {
        const { admin } = this.props
        if (!admin || !this.props.onSendPasswordReset) {
            return
        }

        this.props.onSendPasswordReset().then(() => {
            this.setState({ showPasswordResetModal: false })
        })
    }
    openPasswordResetModal = () => {
        this.setState({ showPasswordResetModal: true })
    }
    closePasswordResetModal = () => {
        this.setState({ showPasswordResetModal: false })
    }

    onUnlockAccount = async () => {
        const { admin } = this.props

        if (!admin || !this.props.onUnlockAccount) {
            return
        }

        const accountName = admin.name.trim() ? `${admin.name}'s` : 'this'

        if (window.confirm(`Do you want to unlock ${accountName} account?`)) {
            await this.props.onUnlockAccount()
            window.alert(`Account unlocked.`)
        }
    }

    onChangeAdmin(modifier: (a: AdminState) => void) {
        this.setState(
            iassign(
                this.state,
                next => next.admin,
                next => {
                    modifier(next)
                    return next
                },
            ),
            () => this.setState({ isDirty: true }),
        )
    }

    toggleSelectAllPermissions = (checked: boolean) => {
        this.setState(
            iassign(
                this.state,
                next => next.admin,
                next => {
                    next.permissions = checked ? _.clone(this.props.permissionList) : []
                    return next
                },
            ),
            () => this.setState({ isDirty: true }),
        )
    }

    onSetPermission(permission: Models.Permission) {
        this.onChangeAdmin(a => {
            if (this.props.admin && a.permissions == null) {
                a.permissions = _.clone(this.props.admin.permissions)
            }

            if (a.permissions == null) {
                a.permissions = [permission]
                return
            }

            const i = a.permissions.findIndex(p => p.type === permission.type)
            if (i < 0) {
                a.permissions.push(permission)
            } else {
                a.permissions.splice(i, 1)
            }
        })
    }

    render() {
        const { permissionList, submitButtonText, admin: propsAdmin } = this.props

        const permissionListFixedLabel = permissionList.map(permission => {
            if (permission.type === 'access_yext') {
                permission.displayName = 'Yext User ID'
            }
            return permission
        })

        const sortedPermissionList = permissionListFixedLabel.sort((a, b) => {
            return a.displayName.localeCompare(b.displayName)
        })

        const admin =
            propsAdmin == null
                ? this.state.admin
                : {
                      name: this.state.admin.name ?? propsAdmin.name,
                      username: this.state.admin.username ?? propsAdmin.username,
                      permissions: this.state.admin.permissions || [...propsAdmin.permissions],
                      type: this.state.admin.type || propsAdmin.type.value,
                      yext_user_id: this.state.admin.yext_user_id ?? propsAdmin.yextUserId,
                  }

        return (
            <div className={classNames('manage-admin')}>
                <div className="content">
                    <div className="name-title" title={propsAdmin && propsAdmin.id}>
                        {propsAdmin ? propsAdmin.name : 'New Admin'}
                    </div>
                    <div className="fields">
                        <div className="name">
                            <TextField
                                fullWidth={true}
                                value={admin.name || ''}
                                onChange={e => this.onChangeAdmin(a => (a.name = e.target.value))}
                                label="First Name & Last Name"
                                variant="standard"
                            />
                        </div>
                        <div className="email">
                            <TextField
                                fullWidth={true}
                                type="email"
                                value={admin.username || ''}
                                onChange={e => this.onChangeAdmin(a => (a.username = e.target.value))}
                                label="Email"
                                variant="standard"
                            />
                            {this.isInvalidUsername && <span className="email-error">Email address is not valid</span>}
                        </div>
                    </div>
                    {propsAdmin && (
                        <>
                            <div className="password-reset">
                                <Button className="send-reset" onClick={this.openPasswordResetModal}>
                                    Password Reset
                                </Button>
                            </div>

                            <ConfirmModal
                                title={`Send password reset to ${admin.name} at ${admin.username}?`}
                                subtitle=""
                                discardText="Cancel"
                                confirmText="Confirm"
                                open={this.state.showPasswordResetModal}
                                onClose={this.closePasswordResetModal}
                                onConfirm={this.confirmPasswordReset}
                                onDiscard={this.closePasswordResetModal}
                            />
                        </>
                    )}
                    {propsAdmin?.lockedUntil && (
                        <div className="password-reset">
                            <Button className="send-reset" onClick={this.onUnlockAccount}>
                                Unlock Account
                            </Button>
                        </div>
                    )}
                    <div className={`account-types ${propsAdmin ? ' with-reset-password' : ''}`}>
                        <div className="types">
                            <CustomMultiselectField
                                items={[SimplifeyeStaff, PartnerAdmin, PattersonStaff]}
                                maxSelected={1}
                                selectedItems={[admin.type || '']}
                                keyProperty="value"
                                displayProperty="displayName"
                                placeholder="Select Account Type"
                                label="Account Type"
                                disabled={propsAdmin != null}
                                onSelectElement={(values: string[]) => this.onChangeAdmin(a => (a.type = values[0]))}
                            />
                        </div>
                        <div className="tooltip-simplifeye-staff">
                            <CustomTooltipV2
                                tooltipText={
                                    <div>
                                        A ‘Simplifeye Staff’ member is a person who is a direct employee of Simplifeye.
                                        <br />
                                        They have an elevated set of permissions and actions compared to a ‘Partner
                                        Admin’ by default.
                                        <br />A ‘Patterson Staff’ user could see only Patterson-supported customers.
                                    </div>
                                }
                                type="click"
                            />
                        </div>
                    </div>
                    <div className="permission-types">
                        <div className="label-wrapper">
                            <div className="label">Permission Types</div>
                            <div className="select-all">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(_1, checked) => this.toggleSelectAllPermissions(checked)}
                                            checked={
                                                admin.permissions?.filter(p => p.id !== 1)?.length ===
                                                permissionList.length
                                            }
                                            color="primary"
                                        />
                                    }
                                    label="Select All"
                                />
                            </div>
                        </div>
                        <div className="permissions">
                            {sortedPermissionList.map(permission => (
                                <div key={permission.id} className="permission">
                                    <Checkbox
                                        color="primary"
                                        checked={
                                            (admin.permissions &&
                                                admin.permissions.find(p => p.type === permission.type) != null) ||
                                            false
                                        }
                                        onClick={() => this.onSetPermission(permission)}
                                    />
                                    <ListItemText
                                        className="manage-admin__list-item-text"
                                        primary={permission.displayName}
                                    />
                                    {permission.type === 'access_yext' && (
                                        <CustomField
                                            customFieldType={CustomFieldType.INPUT}
                                            inputType="text"
                                            value={admin.yext_user_id || ''}
                                            placeholder=""
                                            label=""
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                this.onChangeAdmin(a => (a.yext_user_id = event.target.value))
                                            }
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <button
                    className={classNames('bottom-save-button', { 'can-save': this.canSave && this.state.isDirty })}
                    disabled={!this.canSave || !this.state.isDirty}
                    onClick={this.onSave}
                >
                    {submitButtonText}
                </button>
            </div>
        )
    }
}

export default ManageAdmin
