import moment from 'moment'

import * as Factory from './'

/* tslint:disable */
const transcript = `
Simplifeye: Welcome to our practice. Who do I have of speaking with today?

John Troy: My name is John… is the practice currently accepting new patients?

Simplifeye: We are! Have you seen an eye doctor recently?

John Troy: I have not. I have been putting it off for quite some time. For the last 6 months or so I have had a hard time reading small print. I figured it was time to see an eye doctor

Simplifeye: We’re glad you’ve found us! Let’s get you started – May I please have your last name and best phone number for correspondence?

John Troy: It’s Troy. 646-681-3776

Simplifeye: Noted on that. What is the best email for correspondence?

John Troy: sample@name.com. Are you able to tell me if the practice accepts Guardian insurance?

Simplifeye: Thank you for providing your email. And yes, we are in-network with Guardian. We confirm all appointments by phone. When a patient coordinator reaches out to you, they can discuss your coverage in further detail. For our records, what is your date of birth?

John Troy: 07/06/1985.

Simplifeye: Thank you. Which day and time is best for you to come into the office so I can take note of your preference?

John Troy: I think Wednesday’s are best and probably mornings, but I’m somewhat flexible.

Simplifeye: Good to know – thank you for that. And lastly, how did you hear about the practice?

John Troy: I ran a Google search on, “eye doctors near me” and you folks were one of the ones I looked at. Glad you have live chat since the practice didn’t answer the phone

Simplifeye: We are always here. Feel free to use as a resource! I have everything we need, and a patient coordinator will be following up with you shortly to confirm the day and time of your appointment. They will also clarify any insurance questions you have. While you have me here, is there anything else that I can help you out with today?

John Troy: Nope! I think I am all set

Simplifeye: Have a great day, John!

`
const reason =
    'Patient would like to schedule an annual eye exam. Patient has concerns with near sighted vision, particularly with reading'
/* tslint:enable */

const OptometryPatientDataTestFactory: ModelsV2.Referrals.ReferralPatient = {
    ...Factory.ReferralPatientFactory,
    is_primary: true,
    first_name: 'John',
    last_name: 'Troy',
    date_of_birth: '07/06/1985',
    desired_appointment_time: moment()
        .add(1, 'd')
        .format('MM/DD/YYYY'),
    email: 'sample@name.com',
    inbound_source: 'Google Search',
    phone_number: '+16466813776',
    reason: reason,
    insurance_provider: 'Guardian',
    patient_is_policy_holder: true,
}

export const OptometryTestFactory: ModelsV2.Referrals.ReferralV2 = {
    ...Factory.ReferralFactory,
    date: moment().format('MM/DD/YYYY'),
    time: moment().format('HH:mm:ss'),
    is_test: true,
    created: '',
    transcript: transcript,
    tags: ['is_test'],
    amplify_referral_patient: [OptometryPatientDataTestFactory],
    amplify_status: {
        id: 0,
        value: 'complete',
        display: '',
        display_long: '',
    },
}
