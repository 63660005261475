import moment from 'moment'

import * as Factory from './'

/* tslint:disable */
const transcript = `
Simplifeye: Hi! Welcome to our practice.  Who do I have the pleasure of speaking with today?

Tom Smith: My name is Tom Smith...are you accepting new patients?

Simplifeye: Absolutely! Dr. Strauss would love to see you. Have you seen a dentist recently?

Tom Smith: Not for about 2 years.  I really need a cleaning.  I just haven’t been in a while because my last dentist was kind of rough and I really didn’t want to take the time to find another.  I’ve also heard about sedation dentistry does Dr. Strauss provide that?

Simplifeye: Absolutely! Dr. Strauss will do everything to make your appointment as comfortable as possible.  There are just a couple more pieces of information to get you scheduled. Is there a good phone number to reach you?

Tom Smith: 555-555-5555

Simplifeye: Is that mobile, home, or work?

Tom Smith: Mobile

Simplifeye: Do you have another number that I could also reach you at?

Tom Smith: 646-681-3776 Home

Simplifeye: Perfect.  Thanks so much!

Simplifeye: Can I also get your email address?

Tom Smith: sample@name.com

Simplifeye: And last, what is your date of birth?

Tom Smith: 11/23/1983

Simplifeye: Thank you.  Dr. Strauss sees patients on Mondays and Wednesdays. Which day and time of day is good for you?

Tom Smith: I think Wednesdays are best and probably mornings, but I’m somewhat flexible.

Simplifeye: Ok.  Thank you.  Dr. Strauss takes multiple types of insurance.  If you have your insurance company’s name, I can include that in your information as well!

Tom Smith:  I definitely do.  I have Delta Dental.  Does that work?

Simplifeye:  Dr. Strauss accepts Delta Dental.  Thank you for that information.  Lastly, how did you hear about our practice?

Tom Smith: I was just googling dental practices.

Simplifeye: That’s great.  Ok, someone from our practice will be reaching out to your by phone to confirm your appointment date and time very soon.  Is there anything else I can help you with today?

Tom Smith: Nope!  Thanks so much!

Simplifeye: Have a great day!
`

const reason =
    'Patient wanted to schedule a new patient consultation and cleaning. Patient is mainly concerned about possible pain when visiting a dentist and would prefer sedation if available or needed.'
/* tslint:enable */

const DentalPatientDataTestFactory: ModelsV2.Referrals.ReferralPatient = {
    ...Factory.ReferralPatientFactory,
    is_primary: true,
    first_name: 'Tom',
    last_name: 'Smith',
    date_of_birth: '11/23/1983',
    desired_appointment_time: 'Next Wednesday Morning',
    email: 'sample@name.com',
    inbound_source: 'Google Search',
    phone_number: '+16466813776',
    reason: reason,
    insurance_provider: 'Delta Dental',
    patient_is_policy_holder: true,
}

export const DentalTestFactory: ModelsV2.Referrals.ReferralV2 = {
    ...Factory.ReferralFactory,
    date: moment().format('MM/DD/YYYY'),
    time: moment().format('HH:mm:ss'),
    is_test: true,
    created: '',
    transcript: transcript,
    tags: ['is_test'],
    amplify_referral_patient: [DentalPatientDataTestFactory],
    amplify_status: {
        id: 0,
        value: 'complete',
        display: '',
        display_long: '',
    },
}
