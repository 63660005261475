import iassign from 'immutable-assign'
import _ from 'lodash'

import { ReceiveAdmin, ReceiveAdminPermissions, ReceiveAdmins, ReceiveAllAdmins } from './actions'

export type AdminState = {
    admins: { [key: string]: Models.Account }
    permissions: Models.Permission[]
    adminsPaginationInfo?: Models.PaginationInfo
    allAdmins: Models.Account[]
}

const initialState = (): AdminState => ({
    admins: {},
    permissions: [],
    adminsPaginationInfo: {
        allPages: 1,
        allRows: 0,
    },
    allAdmins: [],
})

type PracticesAction = ReceiveAdmin | ReceiveAdmins | ReceiveAdminPermissions | ReceiveAllAdmins

export function mapAccount(account: Api.Account): Models.Account {
    return {
        id: account.id,
        created: new Date(account.created),
        username: account.username,
        firstName: account.first_name,
        lastName: account.last_name,
        name: `${account.first_name} ${account.last_name}`,
        type: {
            id: account.type.id,
            value: account.type.value,
            displayName: account.type.display_name,
        },
        permissions: mapPermissions(account.permissions),
        active: account.active,
        accepted: account.accepted,
        medicalRole: account.medical_role,
        accountStatus: account.account_status,
        phoneNumbers: account.phone_numbers,
        emails: account.emails,
        lockedUntil: account.locked_until,
        yextUserId: account.yext_user_id,
        isVyneCreated: account.is_vyne_created,
    }
}

export function mapPermissions(permissions: Array<Api.Permission>): Array<Models.Permission> {
    return permissions.map(p => ({
        id: p.id,
        type: p.type,
        displayName: p.display_name,
        isPublic: p.is_public,
    }))
}

export function reducer(state: AdminState = initialState(), action: PracticesAction): AdminState {
    switch (action.type) {
        case 'RECEIVE_ADMINS': {
            return iassign(state, next => {
                const admins = _(action.admins)
                    .map(mapAccount)
                    .concat(Object.values(state.admins))
                    .uniqBy('id')
                    .sortBy('created')
                    .keyBy('id')
                    .value()

                next.admins = admins
                next.adminsPaginationInfo = action.paginationInfo
                return next
            })
        }
        case 'RECEIVE_ALL_ADMINS': {
            return iassign(state, next => {
                const allAdmins = action.admins.map(mapAccount)

                next.allAdmins = allAdmins

                return next
            })
        }
        case 'RECEIVE_ADMIN': {
            return iassign(
                state,
                next => next.admins[action.admin.id],
                next => {
                    return mapAccount(action.admin)
                },
            )
        }
        case 'RECEIVE_ADMIN_PERMISSIONS': {
            return iassign(state, next => {
                next.permissions = mapPermissions(action.permissions)
                return next
            })
        }
        default:
            return state
    }
}
