import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import Fab from '@mui/material/Fab'
import Modal from '@mui/material/Modal'
import classNames from 'classnames'
import _ from 'lodash'

import Api from '../../Api'
import { setRedirect } from '../../appActions'
import { RootState } from '../../appReducer'
import { useAppDispatch } from '../../util/useAppDispatch'
import { SearchBar } from '../shared/custom-fields/SearchBar'
import Paginator from '../shared/Paginator'
import { ArrowDropDown } from '../shared/svgIcons'

import { createAdmin, fetchAdminPermissions, fetchAdminsV2 } from './actions'
import AdminItem from './AdminItem'
import ManageAdmin from './ManageAdmin'

import './AdminList.sass'

const AdminList = () => {
    const [showInviteModal, setShowInviteModal] = useState(false)

    const [searchTerms, setSearchTerms] = useState<ApiV2.Admin.AdminUsersSearchTerms>({
        page: 1,
        search: '',
        sort: 'created',
        order: 'desc',
    })

    const admins = useSelector((state: RootState) => state.admin.admins)
    const adminList = useSelector((state: RootState) => state.navigation.admins.list)
    const permissions = useSelector((state: RootState) => state.admin.permissions)
    const paginationInfo = useSelector((state: RootState) => state.admin.adminsPaginationInfo)

    const dispatch = useAppDispatch()
    const throttledFetchAdmins = useCallback(
        _.throttle((searchTerms: ApiV2.Admin.AdminUsersSearchTerms) => {
            dispatch(fetchAdminsV2('admins', { ...searchTerms }))
        }, 750),
        [],
    )

    useEffect(() => {
        dispatch(fetchAdminPermissions())
        async function fetchAdminData() {
            await Api.Admin.list('admins', { limit: 0 })
        }
        fetchAdminData()
    }, [dispatch])

    useEffect(() => {
        throttledFetchAdmins(searchTerms)
    }, [searchTerms, throttledFetchAdmins])

    const selectPage = (page: number) => {
        setSearchTermsState({ page })
    }
    const searchByKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target && e.target.value
        setSearchTermsState({ search, page: 1 })
    }
    const clearSearch = () => {
        const search = ''
        setSearchTermsState({ search, page: 1 })
    }
    const setSearchTermsState = async (nextSearchTerms: ApiV2.Admin.AdminUsersSearchTerms) => {
        await setSearchTerms({
            ...searchTerms,
            ...nextSearchTerms,
        })
    }

    const getSortingDirectionOfProperty = (sortByProperty: string) => {
        if (sortByProperty === searchTerms.sort) {
            return searchTerms.order === 'desc' ? 'asc' : 'desc'
        }

        return 'desc'
    }
    const sortByProperty = (sort: string) => {
        const order = getSortingDirectionOfProperty(sort)
        setSearchTermsState({ sort, order })
    }

    const onShowInviteModal = () => {
        setShowInviteModal(true)
    }
    const onCloseInviteModal = () => {
        setShowInviteModal(false)
    }

    return (
        <div className={classNames('admin-list')}>
            <div className="bar">
                <div className="accounts-header">
                    <div className="accounts-statistics">
                        <div className="manage-shortcuts" onClick={() => dispatch(setRedirect('/admins/shortcuts'))}>
                            <i className="material-icons shortcuts-icon">speaker_notes</i>
                            Manage Shortcuts
                        </div>
                    </div>
                    <div className="invite-button">
                        <Fab
                            color="primary"
                            className={classNames('button')}
                            onClick={onShowInviteModal}
                            title="Invite new admin"
                        >
                            <i className="material-icons" style={{ color: 'black' }}>
                                add
                            </i>
                        </Fab>
                    </div>
                </div>
            </div>
            <div className="container">
                {showInviteModal && (
                    <Modal
                        className="pst-modal"
                        open={true}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                onCloseInviteModal()
                            }
                        }}
                        disableEscapeKeyDown={true}
                    >
                        <div className="contents manage-admin-modal">
                            <div className="close-modal-button" onClick={onCloseInviteModal}>
                                <i className="material-icons">close</i>
                            </div>
                            <ManageAdmin
                                permissionList={permissions}
                                submitButtonText="Create Admin"
                                onClose={onCloseInviteModal}
                                onSave={(admin: Api.AccountUpdate) => {
                                    dispatch(createAdmin(admin as Api.AccountCreate)).then(() => {
                                        throttledFetchAdmins(searchTerms)
                                        onCloseInviteModal()
                                    })
                                }}
                            />
                        </div>
                    </Modal>
                )}
                <SearchBar
                    value={searchTerms.search as string}
                    onChange={searchByKeyword}
                    onClear={clearSearch}
                    isMessageShown={(searchTerms.search as string).length > 0 && !adminList.length}
                    noResultsMessage="No records found for selected criteria"
                    placeholder="Search by Admin Name or Email Address"
                />
                <Table className="admin-table">
                    <TableHead className="headers">
                        <TableRow>
                            <TableCell className="header-select">
                                <TableSortLabel
                                    active={searchTerms.sort === 'last_name'}
                                    direction={searchTerms.order}
                                    onClick={() => {
                                        sortByProperty('last_name')
                                    }}
                                    IconComponent={ArrowDropDown}
                                >
                                    Last Name, First Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="header-select">
                                <TableSortLabel
                                    active={searchTerms.sort === 'username'}
                                    direction={searchTerms.order}
                                    onClick={() => {
                                        sortByProperty('username')
                                    }}
                                    IconComponent={ArrowDropDown}
                                >
                                    Email Address
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="header-select">
                                <TableSortLabel
                                    active={searchTerms.sort === 'account_type.value'}
                                    direction={searchTerms.order}
                                    onClick={() => {
                                        sortByProperty('account_type.value')
                                    }}
                                    IconComponent={ArrowDropDown}
                                >
                                    Account Type
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="header-select">Permissions</TableCell>
                            <TableCell className="header-select">
                                <TableSortLabel
                                    active={searchTerms.sort === 'created'}
                                    direction={searchTerms.order}
                                    onClick={() => {
                                        sortByProperty('created')
                                    }}
                                    IconComponent={ArrowDropDown}
                                >
                                    Acct. Created
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="header-select" style={{ color: 'rgba(0,0,0,0)' }}>
                                MANAGE
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="archived-amplify-chat-list">
                        {adminList.map(adminId => {
                            const admin = admins[adminId]
                            return <AdminItem key={admin.id} admin={admin} />
                        })}
                    </TableBody>
                </Table>
                <div className="paginator-wrapper">
                    <Paginator
                        currentPage={searchTerms.page as number}
                        paginationInfo={paginationInfo}
                        selectPage={selectPage}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminList
