import ApiV2 from '../../../ApiV2'
import { AppThunk } from '../../../appStore'
import {
    ConnectedPracticeLocation,
    PracticeLocationAvailabilities,
    PracticeLocationAvailability,
    PracticeLocationSchedulingOperatory,
    PracticeLocationSchedulingProvider,
} from '../../../models/v2/Practice'

export enum AvailabilityActionTypes {
    RECEIVE_CONNECTED_PRACTICE_LOCATIONS = '@V2/RECEIVE_CONNECTED_PRACTICE_LOCATIONS',
    RECEIVE_PRACTICE_LOCATION_SCHEDULING_OPERATORIES = '@V2/RECEIVE_PRACTICE_LOCATION_SCHEDULING_OPERATORIES',
    RECEIVE_PRACTICE_LOCATION_SCHEDULING_PROVIDERS = '@V2/RECEIVE_PRACTICE_LOCATION_SCHEDULING_PROVIDERS',
    SET_SELECTED_LOCATION = '@V2/SET_SELECTED_LOCATION',
    SET_SELECTED_OPERATORIES = '@V2/SET_SELECTED_OPERATORIES',
    SET_SELECTED_PROVIDERS = '@V2/SET_SELECTED_PROVIDERS',
    RECEIVE_PRACTICE_LOCATION_AVAILABILITIES = '@V2/RECEIVE_PRACTICE_LOCATION_AVAILABILITIES',
}

export type ReceiveConnectedPracticeLocations = {
    type: AvailabilityActionTypes.RECEIVE_CONNECTED_PRACTICE_LOCATIONS
    practiceId: string
    connectedLocations: ConnectedPracticeLocation[]
}

export type SetSelectedLocation = {
    type: AvailabilityActionTypes.SET_SELECTED_LOCATION
    practiceId: string
    locationId: string
}

export type SetSelectedOperatories = {
    type: AvailabilityActionTypes.SET_SELECTED_OPERATORIES
    practiceId: string
    operatoryIds: string[]
}

export type SetSelectedProviders = {
    type: AvailabilityActionTypes.SET_SELECTED_PROVIDERS
    practiceId: string
    providerIds: string[]
}

export type ReceivePracticeLocationSchedulingOperatories = {
    type: AvailabilityActionTypes.RECEIVE_PRACTICE_LOCATION_SCHEDULING_OPERATORIES
    locationId: string
    schedulingOperatories: PracticeLocationSchedulingOperatory[]
}

export type ReceivePracticeLocationSchedulingProviders = {
    type: AvailabilityActionTypes.RECEIVE_PRACTICE_LOCATION_SCHEDULING_PROVIDERS
    locationId: string
    schedulingProviders: PracticeLocationSchedulingProvider[]
}

export type ReceivePracticeLocationSchedulingAvailabilities = {
    type: AvailabilityActionTypes.RECEIVE_PRACTICE_LOCATION_AVAILABILITIES
    locationId: string
    availabilities: PracticeLocationAvailabilities
}

export function receiveConnectedPracticeLocations(
    practiceId: string,
    connectedLocations: ConnectedPracticeLocation[],
): ReceiveConnectedPracticeLocations {
    return {
        type: AvailabilityActionTypes.RECEIVE_CONNECTED_PRACTICE_LOCATIONS,
        practiceId,
        connectedLocations,
    }
}

export function receivePracticeLocationSchedulingOperatories(
    locationId: string,
    schedulingOperatories: PracticeLocationSchedulingOperatory[],
): ReceivePracticeLocationSchedulingOperatories {
    return {
        type: AvailabilityActionTypes.RECEIVE_PRACTICE_LOCATION_SCHEDULING_OPERATORIES,
        locationId,
        schedulingOperatories,
    }
}

export function receivePracticeLocationSchedulingProviders(
    locationId: string,
    schedulingProviders: PracticeLocationSchedulingProvider[],
): ReceivePracticeLocationSchedulingProviders {
    return {
        type: AvailabilityActionTypes.RECEIVE_PRACTICE_LOCATION_SCHEDULING_PROVIDERS,
        locationId,
        schedulingProviders,
    }
}

export function receivePracticeLocationAvailabilities(
    locationId: string,
    availabilities: PracticeLocationAvailabilities,
): ReceivePracticeLocationSchedulingAvailabilities {
    return {
        type: AvailabilityActionTypes.RECEIVE_PRACTICE_LOCATION_AVAILABILITIES,
        locationId,
        availabilities,
    }
}

export function setSelectedLocation(practiceId: string, locationId: string): SetSelectedLocation {
    return {
        type: AvailabilityActionTypes.SET_SELECTED_LOCATION,
        practiceId,
        locationId,
    }
}

export function setSelectedOperatory(practiceId: string, operatoryIds: string[]): SetSelectedOperatories {
    return {
        type: AvailabilityActionTypes.SET_SELECTED_OPERATORIES,
        practiceId,
        operatoryIds,
    }
}

export function setSelectedProvider(practiceId: string, providerIds: string[]): SetSelectedProviders {
    return {
        type: AvailabilityActionTypes.SET_SELECTED_PROVIDERS,
        practiceId,
        providerIds,
    }
}

export function fetchConnectedPracticeLocations(
    practiceId: string,
    page: number = 1,
): AppThunk<Promise<ConnectedPracticeLocation[]>> {
    return async (dispatch: any) => {
        const connectedLocations = await ApiV2.Practice.getAllConnectedPracticeLocations(practiceId, page)
        await dispatch(receiveConnectedPracticeLocations(practiceId, connectedLocations))
        return connectedLocations
    }
}

export function fetchPracticeLocationSchedulingOperatories(
    locationId: string,
): AppThunk<Promise<PracticeLocationSchedulingOperatory[]>> {
    return async (dispatch: any) => {
        const schedulingOperatories = await ApiV2.Practice.getPracticeLocationSchedulingOperatories(locationId)
        await dispatch(receivePracticeLocationSchedulingOperatories(locationId, schedulingOperatories))
        return schedulingOperatories
    }
}

export function fetchPracticeLocationSchedulingProviders(
    locationId: string,
): AppThunk<Promise<PracticeLocationSchedulingProvider[]>> {
    return async (dispatch: any) => {
        const schedulingProviders = await ApiV2.Practice.getPracticeLocationSchedulingProviders(locationId)
        await dispatch(receivePracticeLocationSchedulingProviders(locationId, schedulingProviders))
        return schedulingProviders
    }
}

export function fetchPracticeLocationAvailabilities(
    locationId: string,
    operatoryIds: string[],
    providerIds: string[],
): AppThunk<Promise<PracticeLocationAvailabilities>> {
    return async (dispatch: any) => {
        const availabilities = await ApiV2.Practice.getPracticeLocationAvailabilities(
            locationId,
            operatoryIds,
            providerIds,
        )
        await dispatch(receivePracticeLocationAvailabilities(locationId, availabilities))
        return availabilities
    }
}
