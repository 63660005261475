import { RootState } from 'appReducer'
import Api from '../../../Api'

export const loadSwapTransactionData = (firstName: string, lastName: string, dob: string, transactionId: string) => {
    return async (dispatch: any, getState: () => RootState): Promise<Api.LoadSwapTransactionDataResponse> => {
        const swapTransactionData = await Api.Payments.loadSwapTransactionData(firstName, lastName, dob, transactionId)

        return swapTransactionData.data
    }
}

export const saveSwapTransaction = (transactionId: string, patientId: string, payerId: string) => {
    return async (dispatch: any, getState: () => RootState): Promise<boolean> => {
        await Api.Payments.saveSwapTransaction(transactionId, patientId, payerId)

        return true
    }
}

export const loadPaymentStatus = (transactionId: string) => {
    return async (dispatch: any, getState: () => RootState): Promise<Api.LoadPaymentStatusResponse> => {
        const paymentStatusData = await Api.Payments.loadPaymentStatus(transactionId)

        return paymentStatusData.data
    }
}

export const savePaymentStatus = (transactionId: string, currentStatus: string, newStatus: string) => {
    return async (dispatch: any, getState: () => RootState): Promise<boolean> => {
        await Api.Payments.savePaymentStatus(transactionId, currentStatus, newStatus)

        return true
    }
}

export const loadEmailOrPhoneData = (
    practiceId: string,
    firstName: string,
    lastName: string,
    currentEmail?: string,
    currentPhone?: string,
) => {
    return async (dispatch: any, getState: () => RootState): Promise<Api.LoadEmailDataResponse> => {
        console.log('here3')
        const emailData = await Api.Payments.loadEmailData(practiceId, firstName, lastName, currentEmail, currentPhone)

        return emailData.data
    }
}

export const saveNewEmailOrPhone = (portalUserId: string, payerId: string, email?: string, phoneNumber?: string) => {
    return async (dispatch: any, getState: () => RootState): Promise<boolean> => {
        await Api.Payments.saveNewEmailOrPhone(portalUserId, payerId, email, phoneNumber)

        return true
    }
}

export const loadInvoice = (practiceId: string, transactionId: string) => {
    return async (dispatch: any, getState: () => RootState): Promise<Api.LoadTransactionDataResponse> => {
        const invoiceData = await Api.Payments.loadInvoice(practiceId, transactionId)

        return invoiceData.data
    }
}

export const loadPlanData = (paymentPlanId: string) => {
    return async (dispatch: any, getState: () => RootState): Promise<Api.LoadPlanDataResponse> => {
        const planData = await Api.Payments.loadPlanData(paymentPlanId)

        return planData.data
    }
}

export const savePlanChargeDate = (paymentPlanId: string, test: string) => {
    return async (dispatch: any, getState: () => RootState): Promise<boolean> => {
        await Api.Payments.savePlanChargeDate(paymentPlanId, test)

        return true
    }
}

export const getPayerData = (
    practiceId: string,
    firstName: string,
    lastName: string,
    // dob: string,
    // email: string,
    phoneNumber: string,
) => {
    return async (dispatch: any, getState: () => RootState): Promise<Api.GetPayerDataResponse> => {
        const payerData = await Api.Payments.getPayerData(practiceId, firstName, lastName, phoneNumber)

        return payerData.data
    }
}

export const loadPlanChargeEvents = (paymentPlanId: string, downPaymentChargeEventId?: string) => {
    return async (
        dispatch: any,
        getState: () => RootState,
    ): Promise<{ chargeEvents: Api.LoadPlanChargeEventsResponse; planId: string }> => {
        let finalPaymentPlanId = paymentPlanId as string
        if (downPaymentChargeEventId) {
            const paymentPlanIdData = await Api.Payments.loadPaymentPlanByChargeEventId(downPaymentChargeEventId)
            finalPaymentPlanId = paymentPlanIdData.data.paymentPlanId
        }

        const planChargeEvents = await Api.Payments.loadPlanChargeEventsByPlanId(finalPaymentPlanId)

        return {
            chargeEvents: planChargeEvents.data,
            planId: finalPaymentPlanId,
        }
    }
}

export const loadPaymentPlan = (paymentPlanId?: string, downPaymentChargeEventId?: string) => {
    return async (dispatch: any, getState: () => RootState): Promise<string> => {
        let finalPaymentPlanId = paymentPlanId as string
        if (downPaymentChargeEventId) {
            const paymentPlanIdData = await Api.Payments.loadPaymentPlanByChargeEventId(downPaymentChargeEventId)
            return paymentPlanIdData.data.paymentPlanId
        }

        const paymentPlanData = await Api.Payments.loadPlanData(finalPaymentPlanId)
        // const paymentPlan = await Api.Payments.loadPaymentPlan(paymentPlanId)

        return paymentPlanData.data.paymentPlanId
    }
}

export const cancelPaymentPlan = (paymentPlanId: string) => {
    return async (dispatch: any, getState: () => RootState): Promise<{}> => {
        await Api.Payments.cancelPaymentPlan(paymentPlanId)

        return true
    }
}

export const removePayerPaymentMethods = (paymentMethodIds: string[]) => {
    return async (dispatch: any, getState: () => RootState): Promise<{}> => {
        const removed = await Api.Payments.removePayerPaymentMethods(paymentMethodIds)

        return true
    }
}
