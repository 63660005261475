import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Grid, Switch } from '@mui/material'
import classNames from 'classnames'
import iassign from 'immutable-assign'
import validator from 'validator'

import { AvailableHours, ConnectSurvey, DayOfWeek, DoxyUrlFormElement } from '../../../models/Connect'
import { CustomFieldType } from '../../../models/enums'
import { getTimeZonesForCountry, getTimeZonesWithAbbreviations } from '../../../modules/practices/locations/utils'
import CustomField from '../../shared/custom-fields/CustomField'
import CustomMultiselectField from '../../shared/custom-fields/CustomMultiselectField'
import {
    FormFieldElement,
    isEmail,
    isRequired,
    isURL,
    isUSPhoneNumber,
    isValidLength,
    validate,
} from '../../shared/form-validator/validator'

import { createConnectSurvey, saveConnectSurvey } from './actions'
import AtomToggle from './AtomToggle'
import AvailableHoursForm from './AvailableHoursForm'
import {
    daysOfTheWeek,
    defaultEndTime,
    defaultStartTime,
    insuranceInformationOptions,
    patientOptions,
    StatusValue,
} from './constants'
import EditableField, { Option } from './EditableField'
import EditablePhoneNumberField from './EditablePhoneNumberField'

import './SurveyForm.sass'

interface DoxyURL {
    url: string
    password: string
}

export type WebCodeSurveyDirty = {
    webCodeId: string
    isDirty: boolean
}

export type SurveyFormProps = {
    connectSurvey?: ConnectSurvey
    connectSurveyId?: string
    webCodeId: string
    practice: Models.Practice
    setIsDirty: (isDirty: WebCodeSurveyDirty) => void
}

interface FormMultiFieldElement extends FormFieldElement {
    uniqueKey?: string
}

interface FormElements {
    statusId: FormFieldElement
    doxy_urls: DoxyUrlFormElement[]
    patientTypeId: FormFieldElement
    insuranceInformationStatusId: FormFieldElement
    emails: FormMultiFieldElement[]
    timeZone: FormFieldElement
    availableHours: string[]
    phoneNumber: FormFieldElement
}

type Form = {
    formElements: FormElements
    isFormValid: boolean
    isFormDirty: boolean
    showFormErrors: boolean
}

type Props = SurveyFormProps

const moduleName = 'survey-form'

const SurveyForm = (props: Props) => {
    const dispatch = useDispatch()
    const getInitialFormState = (connectSurvey: ConnectSurvey | undefined): Form => ({
        formElements: {
            statusId: {
                value: connectSurvey?.status.id.toString() || StatusValue.DISABLED.toString(),
                validators: [],
                isValid: true,
                isDirty: false,
            },
            doxy_urls: connectSurvey?.doxy_urls
                ? connectSurvey.doxy_urls.map(({ url, password }, index) => {
                      return {
                          url: {
                              value: url,
                              validators: [
                                  isRequired(),
                                  isURL({ protocols: ['https'], require_protocol: true, require_valid_protocol: true }),
                              ],
                              isValid: false,
                              isDirty: false,
                          },
                          password: {
                              value: password,
                              validators: [isValidLength({ errorMessage: 'Password length is not valid' })],
                              isValid: true,
                              isDirty: false,
                          },
                          uniqueKey: `doxy-url-${Date.now().toString()}${index}`,
                      }
                  })
                : [
                      {
                          url: {
                              value: '',
                              validators: [
                                  isRequired(),
                                  isURL({ protocols: ['https'], require_protocol: true, require_valid_protocol: true }),
                              ],
                              isValid: false,
                              isDirty: false,
                          },

                          password: {
                              value: '',
                              validators: [isValidLength({ errorMessage: 'Password length is not valid' })],
                              isValid: true,
                              isDirty: false,
                          },
                          uniqueKey: `doxy-url-${Date.now().toString()}`,
                      },
                  ],
            patientTypeId: {
                value: connectSurvey?.patientType.id.toString() || '',
                validators: [isRequired()],
                isValid: false,
                isDirty: false,
            },
            insuranceInformationStatusId: {
                value: connectSurvey?.insuranceInformationStatus.id.toString() || '',
                validators: [isRequired()],
                isValid: false,
                isDirty: false,
            },
            emails: connectSurvey?.emails
                ? connectSurvey.emails.map((email, index) => {
                      return {
                          value: email,
                          validators: [isRequired(), isEmail()],
                          isValid: true,
                          isDirty: false,
                          uniqueKey: `emails-${Date.now().toString()}${index}`,
                      }
                  })
                : [
                      {
                          value: '',
                          validators: [isRequired(), isEmail()],
                          isValid: true,
                          isDirty: false,
                          uniqueKey: `emails-${Date.now().toString()}`,
                      },
                  ],
            timeZone: {
                value: connectSurvey?.timeZone || '',
                validators: [isRequired()],
                isValid: false,
                isDirty: false,
            },
            availableHours: [],
            phoneNumber: {
                value: connectSurvey?.phoneNumber || '',
                validators: [isRequired(), isUSPhoneNumber()],
                isValid: false,
                isDirty: false,
            },
        },
        isFormValid: false,
        isFormDirty: false,
        showFormErrors: Boolean(connectSurvey?.id),
    })

    const [form, setForm] = useState<Form>(getInitialFormState(props.connectSurvey))
    const [isAddingNewUrl, setIsAddingNewUrl] = useState<boolean>(false)
    const [showDisabledOverlay, setShowDisabledOverlay] = useState<boolean>(false)
    const [availableHrs, setAvailableHrs] = useState<Array<AvailableHours>>(
        daysOfTheWeek.map(day => {
            return {
                dayOfWeek: day.index as DayOfWeek,
                startTime: defaultStartTime,
                endTime: defaultEndTime,
                available: true,
            }
        }),
    )

    const connectSurveyId = props.connectSurvey?.id

    useEffect(() => {
        if (connectSurveyId) {
            setForm(getInitialFormState(props.connectSurvey))
        }
    }, [connectSurveyId])

    const isEditForm = Boolean(props.connectSurvey?.id)
    const { formElements } = form

    const renderHeaderText = (text: string, marginTop: boolean = false) => {
        return (
            <div
                className={classNames(`${moduleName}__header`, {
                    [`${moduleName}__header--with-margin-top`]: marginTop,
                })}
            >
                <div className={`${moduleName}__header-text`}>{text}</div>
            </div>
        )
    }

    const toggleStatusValue = (statusValue: StatusValue): string => {
        toggleDisabledOverlay(statusValue)
        return statusValue === StatusValue.ENABLED ? StatusValue.DISABLED.toString() : StatusValue.ENABLED.toString()
    }

    const toggleActivateConnect = () => {
        onSaveField('statusId', toggleStatusValue(Number(formElements.statusId.value)))
    }

    const toggleDisabledOverlay = (statusValue: StatusValue) => {
        const isDisabled = statusValue === StatusValue.ENABLED
        setShowDisabledOverlay(isDisabled)
    }

    const addNewEmail = () => {
        setForm(prevForm => {
            return {
                ...prevForm,
                formElements: {
                    ...prevForm.formElements,
                    emails: prevForm.formElements.emails.concat({
                        value: '',
                        validators: [isRequired(), isEmail()],
                        isValid: false,
                        isDirty: false,
                        uniqueKey: `emails-${Date.now().toString()}`,
                    }),
                },
            }
        })
    }

    const removeEmail = (index: number) => {
        const formElementValue = removeItemFromFormFieldValues('emails', index)
        const updateValue = formElementValue.map((f: any) => f.value)
        saveSurveyAction('emails', updateValue)
    }

    const saveDoxyURL = (doxy_url: DoxyURL) => {
        setForm(prevForm => {
            return {
                ...prevForm,
                formElements: {
                    ...prevForm.formElements,
                    doxy_urls: prevForm.formElements.doxy_urls.concat({
                        url: {
                            value: doxy_url.url,
                            validators: [
                                isRequired(),
                                isURL({ protocols: ['https'], require_protocol: true, require_valid_protocol: true }),
                            ],
                            isValid: true,
                            isDirty: true,
                        },
                        password: {
                            value: doxy_url.password,
                            validators: [isValidLength({ errorMessage: 'Password length is not valid' })],
                            isValid: true,
                            isDirty: true,
                        },
                        uniqueKey: `doxy-url-${Date.now().toString()}`,
                    }),
                    isFormDirty: true,
                },
            }
        })

        setIsDirty(true)

        const updatedDoxyUrls = form.formElements.doxy_urls
            .map((doxy_url: DoxyUrlFormElement) => ({ url: doxy_url.url.value, password: doxy_url.password.value }))
            .concat(doxy_url)

        saveSurveyAction('doxy_urls', updatedDoxyUrls)

        setIsAddingNewUrl(false)
    }

    const removeDoxyURL = (index: number) => {
        const doxyUrlElementValue = removeItemFromFormFieldValues('doxy_urls', index)

        const updateDoxyUrlValue = doxyUrlElementValue.map((doxy_url: DoxyUrlFormElement) => ({
            url: doxy_url.url.value,
            password: doxy_url.password.value,
        }))

        saveSurveyAction('doxy_urls', updateDoxyUrlValue)
    }

    const onEditDoxyURL = (doxyURL: Partial<DoxyURL>, index: number) => {
        const updatedDoxyURLs = setDoxyURLsFieldValues(doxyURL, index)
        setIsDirty(true)

        const updateDoxyUrlsValue = updatedDoxyURLs.map((doxy_url: DoxyUrlFormElement) => ({
            url: doxy_url.url.value,
            password: doxy_url.password.value,
        }))
        saveSurveyAction('doxy_urls', updateDoxyUrlsValue)
    }

    const setDoxyURLsFieldValues = (doxyURL: Partial<DoxyURL>, index: number) => {
        const updatedDoxyURLs = iassign(
            form.formElements.doxy_urls,
            next => next[index],
            oldDoxyURL => {
                if (doxyURL.url !== undefined) {
                    oldDoxyURL.url = { ...oldDoxyURL.url, value: doxyURL.url, isDirty: true }
                }

                if (doxyURL.password !== undefined) {
                    oldDoxyURL.password = { ...oldDoxyURL.password, value: doxyURL.password, isDirty: true }
                }

                return oldDoxyURL
            },
        )

        setUpdatedFields('doxy_urls', updatedDoxyURLs)
        return updatedDoxyURLs
    }

    const onSaveField = (fieldName: string, newValue: string, index?: number) => {
        const isArrayField = Array.isArray(formElements[fieldName])
        let formElementValue
        if (isArrayField && index !== undefined) {
            formElementValue = setFormFieldsValues(newValue, fieldName, index)
        } else {
            formElementValue = setFormFieldValue(newValue, fieldName)
        }

        const updateValue = isArrayField ? formElementValue.map((f: any) => f.value) : formElementValue.value

        saveSurveyAction(fieldName, updateValue)
    }

    const saveSurveyAction = (fieldName: string, updateValue: string | object | Array<string> | Array<object>) => {
        if (props.connectSurvey?.id) {
            dispatch(
                saveConnectSurvey(
                    props.connectSurvey.id,
                    { [fieldName]: updateValue },
                    props.practice,
                    props.webCodeId,
                ),
            )
        }
    }

    const saveSurvey = async () => {
        const { formElements } = form
        setForm(prevForm => {
            return { ...prevForm, showFormErrors: true }
        })
        if (form.isFormValid) {
            dispatch(
                createConnectSurvey(
                    {
                        web_code_id: props.webCodeId,
                        status_id: Number(formElements.statusId.value),
                        doxy_urls: formElements.doxy_urls.map(({ url, password }) => ({
                            url: url.value,
                            password: password.value,
                        })),
                        patient_type_id: Number(formElements.patientTypeId.value),
                        insurance_information_status_id: Number(formElements.insuranceInformationStatusId.value),
                        emails: formElements.emails.map(email => email.value),
                        phone_number: formElements.phoneNumber.value,
                        timezone: formElements.timeZone.value,
                        available_hours: availableHrs.map(hour => {
                            return {
                                day_of_week: hour.dayOfWeek,
                                start_time: hour.startTime,
                                end_time: hour.endTime,
                                available: hour.available,
                            }
                        }),
                    },
                    props.practice,
                    props.webCodeId,
                ),
            )

            setIsDirty(false)
        }
    }

    useEffect(() => {
        const isFormValid = validate(formElements)
        setForm(prevForm => {
            return { ...prevForm, isFormValid: isFormValid }
        })
    }, [formElements])

    useEffect(() => {
        const isDisabled = Number(formElements.statusId.value) !== StatusValue.ENABLED
        setShowDisabledOverlay(isDisabled)
    }, [formElements])

    const setIsDirty = (isDirty: boolean) => {
        if (!isEditForm) {
            props.setIsDirty({ webCodeId: props.webCodeId, isDirty: isDirty })
        }
    }

    const onUpdatePhoneNumber = (value: string, field: string) => {
        const newValue = value
        setFormFieldValue(newValue, field)
    }

    const onUpdateMultiField = (event: any, field: string, index: number) => {
        const newValue = event.target.value
        setFormFieldsValues(newValue, field, index)
    }

    const setFormFieldValue = (newValue: string, field: string) => {
        const updated = {
            ...form.formElements[field],
            value: newValue,
            isDirty: true,
        }
        setForm(prevForm => {
            return {
                ...prevForm,
                formElements: { ...prevForm.formElements, [field]: updated },
                isFormDirty: true,
            }
        })
        setIsDirty(true)

        return updated
    }

    const setUpdatedFields = (fieldName: string, updatedFields: any[]) => {
        setForm(prevForm => {
            return {
                ...prevForm,
                formElements: { ...prevForm.formElements, [fieldName]: updatedFields },
                isFormDirty: true,
            }
        })
    }

    const setFormFieldsValues = (newValue: string, fieldName: string, index: number) => {
        const updatedFields = form.formElements[fieldName].map((item: any, j: number) => {
            if (j === index) {
                return {
                    ...item,
                    value: newValue,
                    isDirty: true,
                }
            } else {
                return item
            }
        })
        setUpdatedFields(fieldName, updatedFields)
        return updatedFields
    }

    const removeItemFromFormFieldValues = (fieldName: string, index: number) => {
        const updatedFields = form.formElements[fieldName].filter((item: any, i: number) => {
            return i !== index
        })

        setUpdatedFields(fieldName, updatedFields)
        return updatedFields
    }

    const onUpdateToggle = (value: any, field: string) => {
        onSaveField(field, value)
        return setForm(prevForm => {
            return {
                ...prevForm,
                formElements: {
                    ...prevForm.formElements,
                    [field]: {
                        ...prevForm.formElements[field],
                        value: value,
                    },
                },
                isFormDirty: true,
            }
        })
    }

    const handleSaveAvailableHours = (hours: AvailableHours[]) => {
        setAvailableHrs(hours)
        setIsDirty(true)
    }

    const handleUpdateAvailableHours = (update: AvailableHours, hours: AvailableHours[]) => {
        saveSurveyAction('availableHours', update)
        setAvailableHrs(hours)
    }

    const getTimeZoneOptions = (): Option[] => {
        const options: Option[] = []
        const abbrTimeZones = getTimeZonesWithAbbreviations(getTimeZonesForCountry('US')).map(abt => ({
            display: abt.timezoneString,
            value: abt.timezoneIanaTZ,
        }))
        return options.concat(abbrTimeZones as Option[])
    }

    return (
        <Fragment>
            <div
                className={`${moduleName}__disabled-overlay ${
                    showDisabledOverlay ? moduleName + '__disabled-overlay--show' : ''
                }`}
            ></div>
            <Grid container={true} spacing={0} className={moduleName}>
                <Grid item={true} xs={12}>
                    <div className={`${moduleName}__header`}>
                        {renderHeaderText('Connect Video Conferencing Information')}
                        <div className={`${moduleName}__activate-connect`}>
                            <Switch
                                color="primary"
                                checked={Number(formElements.statusId.value) === StatusValue.ENABLED}
                                onClick={toggleActivateConnect}
                            />
                            <span>Activate Connect</span>
                        </div>
                    </div>
                </Grid>
                {formElements.doxy_urls.length > 0 &&
                    formElements.doxy_urls.map(({ url, password, uniqueKey }, index) => {
                        return (
                            <Fragment key={uniqueKey}>
                                <Grid item={true} xs={6} className={`${moduleName}__urls-container`}>
                                    <EditableField
                                        customFieldType={CustomFieldType.INPUT}
                                        error={form.showFormErrors && url.firstErrorMessage}
                                        inputType="text"
                                        placeholder="https://example.doxy.me"
                                        className={`${moduleName}__field-input-url`}
                                        fieldData={url}
                                        onSave={url => onEditDoxyURL({ url }, index)}
                                        onCancel={url => setDoxyURLsFieldValues({ url }, index)}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            setDoxyURLsFieldValues({ url: event.target.value }, index)
                                        }
                                        forceOpenEdit={url.value === ''}
                                        editButtons={isEditForm}
                                    />
                                </Grid>
                                <Grid item={true} xs={6} className={`${moduleName}__passwords-container`}>
                                    <EditableField
                                        customFieldType={CustomFieldType.INPUT}
                                        error={form.showFormErrors && password.firstErrorMessage}
                                        inputType="text"
                                        className={`${moduleName}__field-input-password`}
                                        fieldData={password}
                                        onSave={password => onEditDoxyURL({ password }, index)}
                                        onCancel={password => setDoxyURLsFieldValues({ password }, index)}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            setDoxyURLsFieldValues({ password: event.target.value }, index)
                                        }
                                        editButtons={isEditForm}
                                    />
                                    {index !== 0 && (
                                        <div className={`${moduleName}__icon-wrapper`}>
                                            <div className={`${moduleName}__delete-icon-wrapper`}>
                                                <i
                                                    className={`material-icons ${moduleName}__delete-icon`}
                                                    onClick={() => removeDoxyURL(index)}
                                                >
                                                    delete
                                                </i>
                                            </div>
                                        </div>
                                    )}
                                </Grid>
                            </Fragment>
                        )
                    })}

                {isAddingNewUrl && <NewURLFields onCancel={() => setIsAddingNewUrl(false)} saveDoxyURL={saveDoxyURL} />}

                <Grid item={true}>
                    <div
                        className={classNames(`${moduleName}__email-action-button`, {
                            [`${moduleName}__email-action-button--disabled`]: !isEditForm || isAddingNewUrl,
                        })}
                        onClick={() => setIsAddingNewUrl(true)}
                    >
                        <i className={`material-icons ${moduleName}__email-icon`}>email</i>
                        <div className={`${moduleName}__email-action-button-text`}>Add another Doxy url</div>
                    </div>
                </Grid>

                <Grid item={true} xs={12}>
                    <div className={`${moduleName}__header`}>
                        {renderHeaderText(
                            isEditForm
                                ? 'Connect Hours'
                                : 'What days and hours would you like Simplifeye Connect to be available?',
                            true,
                        )}
                        <div className={`${moduleName}__time-zone`}>
                            <CustomMultiselectField
                                items={getTimeZoneOptions()}
                                maxSelected={1}
                                search={getTimeZoneOptions().length > 9}
                                selectedItems={[formElements.timeZone.value || '']}
                                keyProperty="value"
                                displayProperty="display"
                                placeholder="Select Time Zone"
                                searchPlaceholder="Search Timezones"
                                onSelectElement={(values: string[]) => setFormFieldValue(values[0] || '', 'timeZone')}
                                error={form.showFormErrors && formElements.timeZone.firstErrorMessage}
                                errorMessage={
                                    form.showFormErrors && formElements.timeZone.firstErrorMessage
                                        ? formElements.timeZone.firstErrorMessage
                                        : ''
                                }
                            />
                            {isEditForm && (
                                <Button
                                    className={`${moduleName}__time-zone-action-button`}
                                    color="primary"
                                    variant="contained"
                                    disabled={!formElements.timeZone.isDirty}
                                    onClick={() => onSaveField('timeZone', formElements.timeZone.value || '')}
                                >
                                    Save
                                </Button>
                            )}
                        </div>
                    </div>
                </Grid>
                <Grid item={true} xs={12}>
                    <AvailableHoursForm
                        isEditForm={isEditForm}
                        availableHours={props.connectSurvey?.availableHours}
                        saveAvailableHours={handleSaveAvailableHours}
                        hasConnectSurvey={Boolean(props.connectSurveyId)}
                        updateAvailableHours={handleUpdateAvailableHours}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    {renderHeaderText('Will you be seeing new and existing patients virtually?', true)}
                </Grid>
                <Grid item={true} xs={12}>
                    <div className={`${moduleName}__patient-toggle`}>
                        <AtomToggle
                            value={formElements.patientTypeId.value}
                            error={form.showFormErrors && formElements.patientTypeId.firstErrorMessage}
                            errorMessage={formElements.patientTypeId.firstErrorMessage}
                            onClick={value => onUpdateToggle(value, 'patientTypeId')}
                            options={patientOptions}
                        />
                    </div>
                </Grid>
                <Grid item={true} xs={12}>
                    {renderHeaderText(
                        'What email address(es) would the practice like to be notified on when a patient is in the waiting room?',
                        true,
                    )}
                </Grid>
                {formElements.emails.length > 0 &&
                    formElements.emails.map((email, index) => {
                        return (
                            <Grid
                                item={true}
                                xs={12}
                                key={email.uniqueKey}
                                className={`${moduleName}__emails-container`}
                            >
                                <EditableField
                                    customFieldType={CustomFieldType.INPUT}
                                    error={form.showFormErrors && email.firstErrorMessage}
                                    inputType="text"
                                    className={`${moduleName}__field-input-email`}
                                    fieldData={email}
                                    onSave={value => onSaveField('emails', value, index)}
                                    onCancel={value => setFormFieldsValues(value, 'emails', index)}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        onUpdateMultiField(event, 'emails', index)
                                    }
                                    forceOpenEdit={email.value === ''}
                                    editButtons={isEditForm}
                                />
                                {index !== 0 && (
                                    <div className={`${moduleName}__icon-wrapper`}>
                                        <div className={`${moduleName}__delete-icon-wrapper`}>
                                            <i
                                                className={`material-icons ${moduleName}__delete-icon`}
                                                onClick={() => removeEmail(index)}
                                            >
                                                delete
                                            </i>
                                        </div>
                                    </div>
                                )}
                            </Grid>
                        )
                    })}

                <Grid item={true}>
                    <div
                        className={classNames(`${moduleName}__email-action-button`, {
                            [`${moduleName}__email-action-button--disabled`]: !isEditForm,
                        })}
                        onClick={addNewEmail}
                    >
                        <i className={`material-icons ${moduleName}__email-icon`}>email</i>
                        <div className={`${moduleName}__email-action-button-text`}>Add another email address</div>
                    </div>
                </Grid>
                <Grid item={true} xs={12}>
                    {renderHeaderText(
                        'What mobile phone number would you like to be notified via text message when a patient is in the waiting room?',
                        true,
                    )}
                    <EditablePhoneNumberField
                        customFieldType={CustomFieldType.INPUT}
                        error={form.showFormErrors && formElements.phoneNumber.firstErrorMessage}
                        fieldData={formElements.phoneNumber}
                        onSave={value => onSaveField('phoneNumber', value)}
                        onCancel={value => setFormFieldValue(value, 'phoneNumber')}
                        onChange={(value: string) => onUpdatePhoneNumber(value, 'phoneNumber')}
                        editButtons={isEditForm}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    {renderHeaderText(
                        'Do you want us to capture insurance information before allowing a patient into the virtual waiting room? ',
                        true,
                    )}
                </Grid>
                <Grid item={true} xs={12}>
                    <div className={`${moduleName}__insurance-toggle`}>
                        <AtomToggle
                            value={formElements.insuranceInformationStatusId.value}
                            error={form.showFormErrors && formElements.insuranceInformationStatusId.firstErrorMessage}
                            errorMessage={formElements.insuranceInformationStatusId.firstErrorMessage}
                            onClick={value => onUpdateToggle(value, 'insuranceInformationStatusId')}
                            options={insuranceInformationOptions}
                        />
                    </div>
                </Grid>
                {!isEditForm && (
                    <Grid item={true} xs={12}>
                        <div className={`${moduleName}__submit`}>
                            <Button
                                disabled={!form.isFormDirty}
                                className={`${moduleName}__submit-button`}
                                onClick={saveSurvey}
                            >
                                SAVE CONNECT INFORMATIONS
                            </Button>
                        </div>
                    </Grid>
                )}
            </Grid>
        </Fragment>
    )
}

interface NewURLFieldsProps {
    onCancel: () => void
    saveDoxyURL: (doxy_url: DoxyURL) => void
}

const NewURLFields = ({ onCancel, saveDoxyURL }: NewURLFieldsProps) => {
    const [url, setUrl] = useState('')
    const [urlError, setUrlError] = useState('')
    const [password, setPassword] = useState('')

    function onURLChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target

        const isUrl = validator.isURL(value, {
            protocols: ['https'],
            require_protocol: true,
            require_valid_protocol: true,
        })

        setUrlError('')

        if (!isUrl) {
            setUrlError('URL is not valid.')
        }

        if (value === '') {
            setUrlError('This field is required.')
        }

        setUrl(value)
    }

    function onPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value)
    }

    function onSaveDoxyURL() {
        if (urlError) {
            return
        }

        saveDoxyURL({ url, password })
    }

    return (
        <Grid container={true} spacing={0}>
            <Grid item={true} xs={6} className={`${moduleName}__new-url-field-container`}>
                <CustomField
                    customFieldType={CustomFieldType.INPUT}
                    inputType="text"
                    placeholder="https://example.doxy.me"
                    value={url}
                    error={Boolean(urlError)}
                    errorMessage={urlError}
                    onChange={onURLChange}
                />
            </Grid>
            <Grid item={true} xs={6} className={`${moduleName}__new-url-field-container`}>
                <CustomField
                    customFieldType={CustomFieldType.INPUT}
                    inputType="text"
                    value={password}
                    onChange={onPasswordChange}
                />
                <div>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button onClick={onSaveDoxyURL} disabled={Boolean(urlError)} color="primary" variant="contained">
                        Save doxy URL
                    </Button>
                </div>
            </Grid>
        </Grid>
    )
}

export default SurveyForm
