import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Grid } from '@mui/material'
import { PracticeLocationSchedulingOperatory, PracticeLocationSchedulingProvider } from 'models/v2/Practice'

import { RootState } from '../../../appReducer'
import CustomMultiselectField from '../../../modules/shared/custom-fields/CustomMultiselectField'
import { moveDefaultLocationToTop } from '../../../modules/shared/sortUtils'
import { useAppDispatch } from '../../../util/useAppDispatch'

import {
    fetchConnectedPracticeLocations,
    fetchPracticeLocationAvailabilities,
    fetchPracticeLocationSchedulingOperatories,
    fetchPracticeLocationSchedulingProviders,
    setSelectedLocation,
    setSelectedOperatory,
    setSelectedProvider,
} from './v2actions'

import './AvailabilityFilters.sass'

type Props = {
    practiceId: string
    setLoading: Dispatch<SetStateAction<boolean>>
}

const moduleName = 'availability-filters'

const AvailabilityFilters = (props: Props) => {
    const { practiceId, setLoading } = props

    const connectedLocations = useSelector(
        (state: RootState) => state.v2.availability.connectedPracticeLocations[practiceId] || [],
    )
    const selectedLocationId = useSelector((state: RootState) => state.v2.availability.selectedLocationId[practiceId])
    const schedulingOperatories = useSelector(
        (state: RootState) => state.v2.availability.practiceLocationSchedulingOperatories[selectedLocationId] || [],
    )
    const schedulingProviders = useSelector(
        (state: RootState) => state.v2.availability.practiceLocationSchedulingProviders[selectedLocationId] || [],
    )
    const selectedOperatoryIds = useSelector(
        (state: RootState) => state.v2.availability.selectedOperatoryIds[practiceId] || [],
    )
    const selectedProviderIds = useSelector(
        (state: RootState) => state.v2.availability.selectedProviderIds[practiceId] || [],
    )

    const applyFilterButtonDisabled = !selectedLocationId || !selectedOperatoryIds.length || !selectedProviderIds.length

    const sortedConnectedLocations = connectedLocations.sort(moveDefaultLocationToTop)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchConnectedPracticeLocations(practiceId))
    }, [practiceId, dispatch])

    useEffect(() => {
        if (selectedLocationId) dispatch(fetchPracticeLocationSchedulingOperatories(selectedLocationId))
    }, [selectedLocationId, dispatch])

    useEffect(() => {
        if (selectedLocationId) dispatch(fetchPracticeLocationSchedulingProviders(selectedLocationId))
    }, [selectedLocationId, dispatch])

    const selectPracticeLocation = (values: string[]) => {
        dispatch(setSelectedLocation(practiceId, values[0] || ''))
        dispatch(setSelectedOperatory(practiceId, []))
        dispatch(setSelectedProvider(practiceId, []))
    }

    const selectOperatory = (operatoryIds: string[]) => {
        dispatch(setSelectedOperatory(practiceId, operatoryIds))
    }

    const selectProvider = (providerIds: string[]) => {
        dispatch(setSelectedProvider(practiceId, providerIds))
    }

    const applyFilter = async () => {
        setLoading(true)
        await dispatch(
            fetchPracticeLocationAvailabilities(selectedLocationId, selectedOperatoryIds, selectedProviderIds),
        )
        setLoading(false)
    }

    const schedulingProvidersWithPmsId = schedulingProviders.map((provider: PracticeLocationSchedulingProvider) => {
        const newProvider = { ...provider }
        if (provider.pms_id) {
            newProvider.full_name =
                newProvider.full_name !== '' ? `${newProvider.pms_id} - ${newProvider.full_name}` : newProvider.pms_id
        }

        return newProvider
    }) as PracticeLocationSchedulingProvider[]

    const schedulingProvidersWithPmsIdSorted = schedulingProvidersWithPmsId.sort((a, b) => {
        const aFullName: string = a.full_name || ''
        return aFullName.localeCompare(b.full_name || '', undefined, { numeric: true, sensitivity: 'base' })
    })

    const schedulingOperatoriesWithPmsOrNum = schedulingOperatories.map(
        (operatory: PracticeLocationSchedulingOperatory) => {
            const newOperatory = { ...operatory }
            if (newOperatory.pms_id) {
                newOperatory.name =
                    newOperatory.name !== '' ? `${newOperatory.pms_id} - ${newOperatory.name}` : newOperatory.pms_id
            }

            if (!newOperatory.pms_id && newOperatory.num) {
                newOperatory.name =
                    newOperatory.name !== '' ? `${newOperatory.num} - ${newOperatory.name}` : newOperatory.num
            }

            return newOperatory
        },
    ) as PracticeLocationSchedulingOperatory[]

    const schedulingOperatoriesWithPmsOrNumSorted = schedulingOperatoriesWithPmsOrNum.sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
    })

    return (
        <div className={moduleName}>
            <Grid container={true} spacing={3} className={moduleName}>
                <Grid item={true} sm={4} xs={6}>
                    <CustomMultiselectField
                        items={sortedConnectedLocations}
                        maxSelected={1}
                        selectedItems={[selectedLocationId || '']}
                        keyProperty="id"
                        search={connectedLocations.length > 8}
                        placeholder="Select Practice Location"
                        label="Select Practice Location"
                        displayProperty="name"
                        onSelectElement={selectPracticeLocation}
                    />
                </Grid>
                <Grid item={true} sm={4} xs={6}>
                    <CustomMultiselectField
                        items={schedulingOperatoriesWithPmsOrNumSorted}
                        selectAllLabel={'Select All Operatories'}
                        selectedItems={selectedOperatoryIds}
                        keyProperty={'id'}
                        search={schedulingOperatoriesWithPmsOrNumSorted.length > 8}
                        placeholder={'Select Operatory'}
                        displayProperty={'name'}
                        onSelectElement={value => {
                            selectOperatory(value)
                        }}
                        label="Select Operatory"
                    ></CustomMultiselectField>
                </Grid>
                <Grid item={true} sm={4} xs={6}>
                    <CustomMultiselectField
                        items={schedulingProvidersWithPmsIdSorted}
                        selectAllLabel={'Select All Providers'}
                        selectedItems={selectedProviderIds}
                        keyProperty={'id'}
                        search={schedulingProvidersWithPmsIdSorted.length > 8}
                        placeholder={'Select Provider'}
                        displayProperty={'full_name'}
                        onSelectElement={value => {
                            selectProvider(value)
                        }}
                        label="Select Provider"
                    ></CustomMultiselectField>
                </Grid>
                <Grid item={true} xs={12} className={`${moduleName}__submit-button-wrapper`}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={applyFilter}
                        disabled={applyFilterButtonDisabled}
                    >
                        Check Availability
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default AvailabilityFilters
