import { mapPermissions } from '../modules/admin/reducer'

import { AccountTypes } from './enums'

export default class AdminAccount implements Models.Account {
    id: string
    username: string
    name: string
    firstName: string
    lastName: string
    type: {
        id: number
        value: string
        displayName: string
    }
    permissions: Array<Models.Permission>
    created: Date
    accepted: boolean
    active: boolean
    medicalRole: string
    accountStatus: Models.AccountStatus
    phoneNumbers: string[]
    emails: string[]
    lockedUntil: Date | null
    yextUserId: string | undefined
    isVyneCreated: boolean | undefined

    constructor(account: Api.Account) {
        this.id = account.id
        this.username = account.username
        this.name = `${account.first_name} ${account.last_name}`
        this.firstName = account.first_name
        this.lastName = account.last_name
        this.type = {
            id: account.type.id,
            value: account.type.value,
            displayName: account.type.display_name,
        }
        this.permissions = mapPermissions(account.permissions)
        this.created = new Date(account.created)
        this.accepted = account.accepted
        this.active = account.active
        this.medicalRole = account.medical_role
        this.accountStatus = account.account_status
        this.phoneNumbers = account.phone_numbers
        this.emails = account.emails
        this.yextUserId = account.yext_user_id
        this.isVyneCreated = account.is_vyne_created
    }

    get isSuperAdmin() {
        return this.type.id === AccountTypes.SuperAdmin
    }

    get isSimplifeyeStaff() {
        return this.isSuperAdmin || this.type.id === AccountTypes.SimplifeyeStaff
    }

    get isPartnerAdmin() {
        return this.isSimplifeyeStaff || this.type.id === AccountTypes.PartnerAdmin
    }

    hasAccess(permission: Models.Permission | string) {
        return (
            this.isSuperAdmin ||
            Boolean(
                this.permissions.find(p =>
                    typeof permission === 'string' ? p.type === permission : p.type === permission.type,
                ),
            )
        )
    }
}
